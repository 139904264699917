
import React, { Component } from 'react'
import { Form, Input, Modal, Button } from 'antd';
import '../../Styles/Global/auth.css';

const axios = require("axios").default;


/**
 *
 *
 * @class Recovery
 * @extends {Component}
 */
class Recovery extends Component {

    /**
     *Creates an instance of Login.
     * @param {*} props
     * @memberof Login
     */
    constructor(props) {
        super(props);
        this.state = {
            statusLoading: 0,
            isRecovery: false,
            isRecoveryLoading: false
        }
    }

    recoveryPassword = React.createRef();
    /**
   * @memberof Login
   * @method   handleRecoveryPassword
   * @description Metodo de recuperación de contraseña
   *
   **/
    handleRecoveryPassword = async value => {
        await this.setState({
            isRecoveryLoading: true
        })
        axios.put("/password/recovery", { email: value.email })
            .then(res => {
                this.setState({
                    isRecovery: false
                })
                Modal.success({
                    title: 'Restablece tu contraseña.',
                    content: '¡El correo ha sido enviado! Revisa tu bandeja de entrada.'
                })
                this.recoveryPassword.current.resetFields();
            })
            .catch(res => {
                console.log(res)
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que sea el mismo con el que te registraste.'
                });
            })
            .finally(() => {
                this.setState({
                    isRecoveryLoading: false
                })
            })
    }

    /**
    * @memberof Login
    *
    * @method redirectTo
    * @description  Redirecciona a cierto link.
    *
    **/
    redirectTo = (to) => {
        this.redirectLink = to;
    }


    render() {
        return (
            <Form onFinish={this.handleRecoveryPassword} layout={"vertical"} className="pd-2" ref={this.recoveryPassword}>
                <Form.Item
                    name="email"
                    label="Escribe tu correo electrónico."
                    rules={[{
                        required: true,
                        message: 'Por favor, ingresa tu correo electrónico.'
                    }]}>
                    <Input type="email" size="large" placeholder="Escribe tu correo electrónico" />
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType='submit'
                        type="primary"
                    >
                        Enviar
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

/**
 *@function ModalRecovery
 *@description 
 */
export default function (props) {
    return (
        <Modal
            visible={props.visible}
            onCancel={props.hideModal}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <Recovery {...props} />
        </Modal>
    )
}
