import React, { Component } from 'react';
import { Button, Row, Col, Typography, List } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import { CardProducto } from '../../Widgets/Cards';
import '../../../Styles/Modules/Public/marketplace.scss';
import { useContext } from 'react';
import Carrito, { SetCarrito } from '../../../Hooks/Carrito';
import ModalAgregarProducto from './ModalAgregarProducto';
import axios from 'axios';

const { Title, Paragraph } = Typography;


/**
 *
 *
 * @export
 * @class StepCotizador
 * @extends {Component}
 */
class StepCotizador extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            index: null
        }
    }

    delete = (index) => {
        const productos = this.props.productos
        const producto = productos.splice(index, 1)

        this.props.setProductos(productos)
    }

    deleteFile = (filename) => {
        axios.post("/upload/delete", {
            filename
        })
            .then(res => {
                console.log("Archivo/Imagen removida con éxito", res);
            })
            .catch(res => {
                console.log("Archivo/Imagen no se puedo remover", res);
            });
    }

    render() {

        return (
            <Row className='marketplace-cotizador' justify={"center"} align={"middle"} gutter={[24, 24]}>
                <Col xs={24}>
                    <Title level={3} className='cotizador-title'>
                        Información de cotización
                    </Title>
                </Col>
                <Col xs={24} md={12} className='cotizador-subtitle'>
                    <Title level={4}>Lista de Productos</Title>
                </Col>
                <Col xs={24} md={12} className='cotizador-actions'>
                    <Button className="action-add" onClick={() => this.setState({modalVisible: true})}>
                        Agregar Producto <PlusOutlined />
                    </Button>
                </Col>
                {
                    this.props.productos.length < 1? ( <Col xs={24} className='cotizador-empty'>
                            <Paragraph >
                                No hay ningún producto a cotizar
                            </Paragraph>
                            <Button
                                className="cotizador-text-add"
                                onClick={() => this.setState({modalVisible: true})}
                            >
                                ¡Agregar Uno!
                            </Button>
                        </Col>
                    ) : (
                        <Col xs={24}>
                            <List
                                className='cotizador-productos'
                                //pagination={{}}
                                dataSource={this.props.productos}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <CardProducto
                                            producto={item}
                                            onEditClick={() => this.setState({index, modalVisible: true})}
                                            onDeleteClick={() => this.delete(index)}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    )
                }
                <ModalAgregarProducto
                    productIdx={this.state.index}
                    visible={this.state.modalVisible}
                    deleteFile={this.deleteFile}
                    onClose={() => {
                        this.setState({index: null, modalVisible: false})
                    }}
                />
            </Row>
        )
    }
}

export default (props) => {

    const carrito = useContext(Carrito)
    const setCarrito = useContext(SetCarrito)

    return <StepCotizador {...props} productos={carrito} setProductos={setCarrito}/>
}